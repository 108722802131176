<template>
    <div class="CkManage">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
            </div>
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" @submit.native.prevent>
                    <el-form-item label="标签">
                        <!-- <el-input v-focus v-model="auxCode" placeholder="请输入标签查询"></el-input> -->
                        <el-input   v-model="auxCode" @keydown.enter.native="courierNumEnterDown"
                            @keyup.enter.native="courierNumEnterUp" placeholder="请输入标签查询" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button  @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="ckSubmit">
                    <el-button  class="btn" @click="handleRk" icon="el-icon-truck">入库</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="auxiliaryCrkList" border style="width: 100%" v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="oddNum" label="单号" align="center"> </el-table-column>
                <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                <el-table-column prop="auxName" label="工具名称" align="center">
                </el-table-column>
                <el-table-column prop="auxType" label="工具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="auxNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="ckJbr" label="出库经办人" align="center">
                </el-table-column>
                <el-table-column prop="ckDate" label="出库日期" align="center">
                </el-table-column>
                <el-table-column prop="ckTime" label="出库时间" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="handleDel(scope.row.auxCode)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog title="入库信息完善" :visible.sync="dialogFormVisible" width="700px" :before-close="closeInfo">
            <el-form :model="auxilRkInfo" :rules="rules" ref="auxilRkInfo">
                <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                    <el-input v-model="auxilRkInfo.rkJbr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="入库日期" :label-width="formLabelWidth" prop="rkDate">
                    <el-date-picker v-model="auxilRkInfo.rkDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="入库时间" :label-width="formLabelWidth" prop="rkTime">
                    <el-time-picker v-model="auxilRkInfo.rkTime" value-format="HH:mm:ss" :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59'
                    }" placeholder="任意时间点">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="auxilRkInfo.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('auxilRkInfo')">取 消</el-button>
                <el-button  class="btn" @click="sure('auxilRkInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { rkInventory, getCkInfo } from "@/api/auxiliaryCrk.js"
export default {
    data() {
        return {
            auxCode: null,
            auxiliaryCrkList: [],
            auxilRkInfo: {
                auxiliaryCrkList: [],
                rkJbr: null,
                rkDate: null,
                rkTime: null,
                remark: null
            },
            rules: {
                rkJbr: [{ required: true, message: '请填写入库经办人' }],
                rkDate: [{ required: true, message: '请选择入库日期' }],
                rkTime: [{ required: true, message: '请选择入库时间' }],
            },
            loading: false,
            // focus: false,
            dialogFormVisible: false,
            formLabelWidth: '80px',
            keyDownTime: 0,
            keyUpTime: 0,
        }
    },
    mounted() {
        
    },
    methods: {
        handleSearch() {
            this.loadAuxilaryInfo()
        },

        loadAuxilaryInfo() {
            if (this.auxCode) {
                this.loading = true
                getCkInfo(this.auxCode).then(res => {
                    setTimeout(() => {
                        this.loading = false
                        if (res.code === '000000') {
                            if (this.auxiliaryCrkList.length > 0) {
                                if (this.auxiliaryCrkList.findIndex(item => item.auxCode === res.t.auxCode) === -1) {
                                    this.auxiliaryCrkList.push(res.t)
                                } else {
                                    this.$message.warning('该辅助工具已添加到入库清单中，请勿重复添加！')
                                }
                            } else {
                                this.auxiliaryCrkList.push(res.t)
                            }

                            var count = 1;
                            this.auxiliaryCrkList.forEach((item) => {
                                item.seq = count++
                            })
                        }
                        this.auxCode = null
                    }, 200)

                })
            }

        },
        handleReset() {
            this.auxCode = null
        },
        handleRk() {
            if (this.auxiliaryCrkList.length === 0) {
                this.$message.warning('请先添加要入库的辅助工具！')
            } else {
                this.auxilRkInfo = {
                    auxiliaryCrkList: this.auxiliaryCrkList,
                    rkJbr: null,
                    rkDate: this.getDate(),
                    rkTime: this.getTime(),
                    remark: null
                }
                this.dialogFormVisible = true
                
            }
            // this.$message.success('出库成功！')
        },

        //提交出库单
        sure(auxilRkInfo) {
            this.$refs[auxilRkInfo].validate(valid => {
                if (valid) {
                    console.log(this.auxilRkInfo)
                    rkInventory(this.auxilRkInfo).then(res => {
                        if (res.code === '000000') {
                            if(res.t){
                                this.$message.success(res.t)
                            }else{
                                this.$message.success('入库成功')
                            }
                           
                        }

                        this.$refs[auxilRkInfo].resetFields()
                        this.dialogFormVisible = false
                        
                        this.auxiliaryCrkList = []
                    })

                }
            })
        },

        //删除
        handleDel(auxCode) {
            this.$confirm('是否移除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let auxiIndex = this.auxiliaryCrkList.findIndex(item => item.auxCode === auxCode)
                if (auxiIndex !== -1) {
                    this.auxiliaryCrkList.splice(auxiIndex, 1)
                    this.$message.success('移除成功！')
                } else {
                    this.$message.error('移除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })
        },

        courierNumEnterDown() {
            this.keyDownTime = new Date().getTime()
        },
        courierNumEnterUp() {
            this.keyUpTime = new Date().getTime()
            if (this.keyUpTime - this.keyDownTime < 30) {
                console.log("扫码完成")
                this.loadAuxilaryInfo()
            } else {
                this.loadAuxilaryInfo()
            }
        },

        closeInfo() {
            this.$refs['auxilRkInfo'].resetFields();
            this.auxilRkInfo = {
                auxiliaryCrkList: [],
                ckJbr: null,
                ckDate: null,
                ckTime: null,
                remark: null
            }
            this.dialogFormVisible = false;
           
        },
        getDate() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
            };
            const newmonth = date.month >= 10 ? date.month : "0" + date.month;
            const day = date.date >= 10 ? date.date : "0" + date.date;
            return date.year + "-" + newmonth + "-" + day;
        },
        getTime() {
            const nowDate2 = new Date();
            const time = {
                HH: nowDate2.getHours,
                mm: nowDate2.getMinutes
                // ss: nowDate2.getSeconds
            }
            const hours = time.HH >= 10 ? time.HH : '0' + time.HH
            const minute = time.mm >= 10 ? time.mm : '0' + time.mm
            // const second = time.ss >=10 ? time.ss :'0'+time.ss
            return hours + '-' + minute + '-00'
        }

    }

}


</script>

<style lang="scss" scoped>
.CkManage {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }


    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;
    }

    .ckSubmit {
        text-align: right;
    }

    .delColor {
        color: red;
    }

}
</style>